import {
  CourseRankingState,
  LicenseState,
  EnrollmentForPeriod,
  LicenseDashboardState,
} from 'redux/schemas/models/license';
import { MonthlyEnrollmentsResponse } from 'redux/schemas/api/license';
import { SearchInSelectOptionIndexes } from 'redux/reducers/content-search';
import { AppTimelineState } from 'redux/reducers/timeline';
import { LecturePageMode } from 'lecture_pages/components';
import { CourseActivitiesEntities, CourseActivitiesNormalizedResult } from './api/course-communications';
import { AlertMessageProps } from './app/alert-message';
import { ConfirmationDialog } from './app/confirmation-dialog';
import { OrgMentor, OrgMentorDashboard, MentorDashboard, OrgMentee } from './models/org-mentors';
import { UserEnrollmentExportDetails, UserEnrollment } from './models/org-users';
import { Course, CollectionDirectory, InfiniteLoadingState, BasicEnrollment, BasicMembership, QrveyState, BasicMentee, FeaturedDiscoveryState } from './models/course';
import { Institution, InstitutionDashboardState, CourseCloning, JourneyCloning, JourneysDashboardState } from './models/institution';
import { HCMConfigState, HCMSettings } from './models/hcm';
import { Institution as CourseFullInstitution, FullCourse, LmsCourse } from './models/courseFull';
import {
  MyAccount,
  Enrollment,
  User,
  MyAccountCourse,
  BaseUsersNormalized,
  CourseUsersNormalized,
} from './models/my-account';
import { Role, RolesNormalized } from './models/role';
import { NLectureSection } from './models/lecture-section';
import { AIDiscussionPrompts, Post, PostsNormalized } from './models/post';
import { CommentsNormalized } from './models/comment';
import { RepliesNormalized } from './models/reply';
import { CollectionLecturePage, LecturePage, LecturePageState, NLecturePage, NovoAIItemType } from './models/lecture-page';
import { LectureComponent, NLectureComponent } from './models/lecture-component';
import { Communication } from './models/course-communication';
import { CourseCommunicationsState } from './app/course-communication';
import { AttachmentLectureComponentNormalized } from './models/attachment';
import { ExerciseSubmissionState, TimelineExercisesNormalized } from './models/exercise';
import { TimelineQuizzesNormalized } from './models/quiz';
import { TimelineTimedQuizzesNormalized } from './models/timed-quiz';
import { TimelineSurveysNormalized } from './models/survey';
import { TimelineTeamSetsNormalized } from './models/team-set';
import { TimelineGroupTeamSetsNormalized } from './models/group-team-set';
import { TimelinePostsNormalized, TimelinePost } from './models/timeline-post';
import { TeamDiscussionsNormalized } from './models/team-discussion';
import { TimelinePeerEvaluationsNormalized } from './models/peer-evaluation';
import { TimelineExternalToolsNormalized } from './models/external-tool';
import { TimelineLiveSessionsNormalized } from './models/live-session';
import { TimelineLectureVideosNormalized } from './models/lecture-video';
import { VideosNormalized } from './models/video';
import {
  VideoPracticeScenariosNormalized,
  VideoPracticeActivitiesNormalized,
  VideoPracticeSubmissionsNormalized,
  PracticeSubmissionComment,
} from './models/video-practice';
import { Team } from './models/team';
import { NormalizedTimeline } from './models/timeline';
import { ProfileRequirementsNormalized } from './models/profile-requirement';
import { OrgProfileFieldsNormalized, CourseProfileQuestionsNormalized } from './models/account-fields';
import { PointsConfigurationNormalized } from './models/points-configuration';
import { PollOptionsNormalizedDictionary, PollQuestionsNormalizedDictionary, PollsNormalizedDictionary, PollVieweeSubmissionNormalizedDirectory } from './models/poll';
import { Bookmark, BookmarksNormalized } from './models/bookmark';
import { OrgProfileState, PracticeRoomState, VideoPracticeState, VideoPracticeSubmissionsState } from './app/video-practice';
import { SkillRatingNormalized, SkillTaggingNormalized, SkillTagNormalized, SkillTagState } from './models/skill-tag';
import { OrgAdmin } from './models/user';
import { LearnerProgressDashboardState, LearnerProgressesNormalized, SectionSubsection } from './models/learner-progress';

import { SubmissionsDictionary } from './models/submissions';
import { TodosDictionary } from './models/todos';
import { ManageCourseAccessModalProps } from './app/course-access-modal';
import { VideoPracticeFeedbackActivitiesNormalized } from './models/video-practice-feedback';
import { CourseAdminDashboardState } from './models/course-admin';
import { IdentityProviderNormalized } from './models/sign-in';
import { CollectionFoldersNormalized } from './api/collections';
import { CollectionCourse, CollectionState } from './models/collections';
import { ExerciseSkillsRatingActivitiesNormalized } from './models/exercise-skills-rating';
import { EdCastConfigState, EdCastSettings } from './models/edcast';
import { VisitedLearningJourneySchema } from './app/learning-journey';
import { CourseCompletionStatusState } from './app/course-completion-status';
import { AutoGenerateProcess } from './app/lecture-component';
import { VideoPracticeSkillsRatingNormalized } from './models/video-practice-skills-feedback';
import { MentoringProgramNormalized } from './models/mentoring-programs';
import { MentoringProgramEnrollment } from './models/mentoring-program-enrollments';
import { MentorshipProgramConnection } from './models/mentorship-program-connections';

import {
  ResponseOptionsNormalized,
  ProgressiveQuizzesNormalized,
  QuizQuestionFeedbackNormalized,
  QuizQuestionResponseNormalized,
  QuizQuestionSubmissionNormalized,
  QuizQuestionsNormalized,
} from './models/progressive-quiz';
import { AutoMatchingStatus, ProgramCounts, SaveStatus } from './app/mentoring-programs';
import { ParticipantCounts } from './app/mentoring-program-participants';
import { MentorshipProgramProfileQuestion } from './models/mentoring-program-profile';
import { AutoMatchingRule } from './models/mentoring-program-matching-rules';

// TODO: find better solution for multiple model types
// in the backend we generally have a few levels of serializers, however different attributes are passed back as well
export type CombinedCourse = Course & MyAccountCourse & FullCourse & CollectionCourse;
export type CombinedCoursesNormalized = { [id: string]: CombinedCourse };

export type CombinedInstitution = Institution & CourseFullInstitution;
export type CombinedInstitutionNormalized = { [id: string]: CombinedInstitution };

export type CombinedPost = Post & TimelinePost;
export type CombinedPostNormalized = TimelinePostsNormalized & PostsNormalized;
export type OrgMentorsNormalized = { [id: number]: OrgMentor };
export type OrgMenteesNormalized = { [id: number]: OrgMentee };
export type OrgUsersNormalized = {
  [id: number]: {
    userDataLoaded?: boolean,
    user?: User,
    enrollmentLoaded?: boolean,
    enrollments?: UserEnrollment[],
    exportInfoLoaded?: boolean,
    exportInfo?: UserEnrollmentExportDetails,
    totalEnrollments?: number,
  },
};

export type DiscussionTranslation = {
  body: string;
  isLoading: boolean;
  error?: boolean;
};

export enum CreateConnectionType {
  ASSIGN_MENTOR,
  ASSIGN_MENTEES,
}

export type ScenariosSupervisorDashboard = {
  id: number;
  title: string;
  pendingSubmissionsCount: number
};

/** Redux 'model' properties are generally intended to be 1-1 mappings of data as received from API which correspond
 * to database tables on the backend. For instance, each course object in 'Courses' should correspond to an individual
 * row of course data from the `courses` table in MySQL. Each data object should be in 'normalized' form, so that nested
 * data objects to other models are actually replaced with ID references.
 */
export interface ModelsState {
  users: { [id: string]: MyAccount & User };
  orgAdmins: { [id: string]: OrgAdmin };
  courses: CombinedCoursesNormalized;
  enrollments: { [id: string]: Enrollment };
  institutions: CombinedInstitutionNormalized;
  orgMentors: OrgMentorsNormalized;
  orgMentees: OrgMenteesNormalized;
  orgUsers: OrgUsersNormalized;
  roles: RolesNormalized;
  courseClonings: CourseCloning[];
  collectionFolders: CollectionFoldersNormalized
  // a list of enrollments with just the columns required in the mylearning flyout.
  basicEnrollments: { [id: string]: BasicEnrollment };
  basicMentees: { [id: string]: BasicMentee };
  basicMemberships: { [id: string]: BasicMembership };
  discoveryOfferings: { [id: string]: Course };
  journeyClonings: JourneyCloning[];

  lectureSections: { [id: string]: NLectureSection },
  lecturePages: { [id: string]: NLecturePage },
  lectureComponents: { [id: string]: NLectureComponent },

  /** Maps course catalog IDs to normalized timeline data */
  timelines: Record<string, NormalizedTimeline>,

  attachments: AttachmentLectureComponentNormalized,
  exercises: TimelineExercisesNormalized,
  exerciseSkillsRatingActivities: ExerciseSkillsRatingActivitiesNormalized,
  quizzes: TimelineQuizzesNormalized,
  timedQuizzes: TimelineTimedQuizzesNormalized,
  progressiveQuizzes: ProgressiveQuizzesNormalized,
  quizQuestions: QuizQuestionsNormalized,
  quizQuestionOptions: ResponseOptionsNormalized,
  quizQuestionFeedbacks: QuizQuestionFeedbackNormalized,
  quizQuestionResponses: QuizQuestionResponseNormalized,
  quizQuestionSubmissions: QuizQuestionSubmissionNormalized,
  surveys: TimelineSurveysNormalized,
  teamSets: TimelineTeamSetsNormalized,
  groupTeamSets: TimelineGroupTeamSetsNormalized,
  posts: CombinedPostNormalized,
  teamDiscussions: TeamDiscussionsNormalized,
  peerEvaluations: TimelinePeerEvaluationsNormalized,
  externalTools: TimelineExternalToolsNormalized,
  liveSessions: TimelineLiveSessionsNormalized,
  lectureVideos: TimelineLectureVideosNormalized,
  video: VideosNormalized,
  profileRequirements: ProfileRequirementsNormalized,
  orgProfileFields: OrgProfileFieldsNormalized,
  courseProfileQuestions: CourseProfileQuestionsNormalized,
  pointsConfigurations: PointsConfigurationNormalized,
  practiceScenarios: VideoPracticeScenariosNormalized,
  practiceActivities: VideoPracticeActivitiesNormalized,
  practiceSubmissions: VideoPracticeSubmissionsNormalized,
  practiceFeedbackActivities: VideoPracticeFeedbackActivitiesNormalized
  videoPracticeSkillsRating: VideoPracticeSkillsRatingNormalized,

  communications: { [id: string]: Communication }

  // TODO: Revise
  teams: Record<string, Team>;
  // These are the team data that we use to create a CSV for admin download
  teamsForCSV: Array<any>;
  groupsForCSV: Array<any>;

  // TODO: consider, does this need to be renamed?
  learnerProgresses: LearnerProgressesNormalized;
  courseOutlineSections: SectionSubsection[];

  // TODO: organize the following data to the correct location
  /** Tracks whether we've finished loading course data */
  license: LicenseState;
  courseRankings: CourseRankingState;
  totalLogins: number;
  totalEnrollments: number;
  monthlyEnrollments: MonthlyEnrollmentsResponse;
  enrollmentsForPeriod: EnrollmentForPeriod;

  comments: CommentsNormalized;
  replies: RepliesNormalized;

  activities?: CourseActivitiesEntities;
  activitiesNormalized?: CourseActivitiesNormalizedResult;
  mentionableMembers: BaseUsersNormalized;
  courseUsers: { [id: string]: CourseUsersNormalized };

  polls: PollsNormalizedDictionary;
  pollQuestions: PollQuestionsNormalizedDictionary;
  pollOptions: PollOptionsNormalizedDictionary;
  pollVieweeSubmission: PollVieweeSubmissionNormalizedDirectory;

  skillTags: SkillTagNormalized;
  skillTagsForProdCourses: SkillTagNormalized;
  skillTaggings: SkillTaggingNormalized
  journeyCollections: CollectionDirectory,
  submissions: SubmissionsDictionary,
  todos: TodosDictionary,
  skillRatings: SkillRatingNormalized,
  identityProviders: IdentityProviderNormalized,
  hcmConfig: HCMSettings,
  edCastConfig: EdCastSettings,
  mentoringPrograms: Record<number, MentoringProgramNormalized>,
  mentoringProgramEnrollments: Record<number, MentoringProgramEnrollment>,
  mentorshipProgramConnections: Record<number, MentorshipProgramConnection>,
  mentorshipProgramProfileQuestions: Record<number, MentorshipProgramProfileQuestion>
  mentorshipProgramMatchingRules: Record<number, AutoMatchingRule>
  supervisorDashboard: {
    scenariosPendingReview: ScenariosSupervisorDashboard[],
  }
}

/** app/feature specific information */
export interface AppState {
  // global data
  currentUserId: number,
  currentInstitutionId: number,
  // TODO: This is only used in one place (when adding a new component), and is not generally useful: we'd be better off storing the currentCatalogId, which I've added below. Remove this after reworking component adds
  currentCourseId: number,
  currentCatalogId: string,
  currentVideoId: number,
  isVideoFromLibrary: boolean,
  visitedLearningJourney: VisitedLearningJourneySchema,

  // global features
  showSavingOverlay: boolean,
  savingOverlayText: string,
  alertMessages: AlertMessageProps[],
  confirmationDialog: ConfirmationDialog,
  showUpdateEmailAlert: boolean,
  autoGenerateProcess: AutoGenerateProcess,

  // Id of the current playing JWPlayer. Kept this to pause this instance
  // when another instance started playing
  currentPlayingVideoPlayerId: string;

  // individual features
  institutionDashboard: InstitutionDashboardState;
  journeysDashboard: JourneysDashboardState;
  licenseDashboard: LicenseDashboardState;
  courseCommunications: CourseCommunicationsState;
  orgMentorAdminDashboard: OrgMentorDashboard;
  practiceRoom: PracticeRoomState;
  videoPractice: VideoPracticeState;
  courseCompletionStatus: Partial<CourseCompletionStatusState>;
  learnerProgressDashboard: LearnerProgressDashboardState;
  lecturePage: LecturePageState;
  destinationLecturePage: {
    id: number,
    mode: LecturePageMode,
    loaded: boolean,
    translated: boolean,
    action: string,
    component: NLectureComponent,
  } // The state to store the data of selected location for copy/move action
  skillTag: SkillTagState;
  qrveyConfig: QrveyState;
  myLearning: InfiniteLoadingState;
  myMentees: InfiniteLoadingState;
  myTeamsAndGroups: InfiniteLoadingState;
  discovery: InfiniteLoadingState;
  featuredDiscovery: FeaturedDiscoveryState;
  exerciseSubmissions: ExerciseSubmissionState;

  // TODO: Move the below properties into the LecturePageState
  /** Components added on the client-side only on a temporary basis before the component is finished loading and then saved
  * on the backend. Typically used for file uploads where we need to render the component (and its loading bar) before
  * the file upload is complete.
  * @type - A mapping of LecturePage ids to mappings of component indices <-> Lecture Components */
  unpersistedComponents: Record<number, Record<number, LectureComponent>>;
  /** The ID of the last component added to the lecture page. Used to enable "Just added" functionality
   * on a component */
  newComponent: number;
  /** Whether the timeline sidebar is currently open in the angularjs app */
  // TODO: Remove this. This is a legacy state bool that connects to values in Angularjs. Should be replaced with
  // app.lecturePage.isLeftPanelVisible
  timelineVisible: boolean;
  teamCount: number;
  groupCount: number;
  teamsUpdating: boolean;
  groupsUpdating: boolean;

  newCommentIdsForCurrentUser: number[];
  newReplyIdsForCurrentUser: number[];

  mentorDashboard: MentorDashboard,
  courseAdminDashboard: CourseAdminDashboardState,
  lastContentSearchString: string;
  searchInSelectedOptionIdx: SearchInSelectOptionIndexes;

  bookmarks: {
    ids: number[];
    all: BookmarksNormalized;
    highlighted: number;
    loading: boolean;
    filter: string;
    courses: {
      [key: string]: {
        ids: number[];
      };
    };
    focusNew: number;
    lastDeleted: Bookmark;
  }
  orgProfile: {
    [id: string]: OrgProfileState,
  };

  sumTotalIntegration: LmsCourse,
  // Course Access Modal
  courseAccessModalProps: ManageCourseAccessModalProps,
  videoPracticeSubmissions: VideoPracticeSubmissionsState
  videoPracticeFeedback: {
    practiceFeedbackCriteriaId?: number
    showPracticeFeedbackModal?: boolean
    showFeedbackInstruction?: boolean
    userHasViewdInstruction?: {
      [id: string]: boolean,
    }
    practiceFeedbackData?: PracticeSubmissionComment,
  }
  // For Team/Group Management
  manageTeam: {
    currentTeamId?: number,
    isUploading?: boolean,
  },

  // Sign In
  loginProviders: {
    isLoading: boolean;
    hasBeenLoaded: boolean;
    withPassword: boolean;
  }

  redirecting?: boolean;

  // To have only one options menu item active
  optionsMenu?: {
    menuItem?: string;
  }
  hcmConfig: HCMConfigState;
  collection: CollectionState;
  edCastConfig: EdCastConfigState;
  timeline: AppTimelineState;

  // To check if a communication to send now is still waiting to be sent.
  currentSendingNowCommunication?: {
    id: number,
    state: string,
  }

  // To have the ordered list of admins when cloning a course
  orderedCourseUsers?: {
    admins: Role[];
    courseRoleUsersCount: {
      [role: string]: number;
    };
  }

  // Value that indicates the component with that index will be generated by AI
  newGeneratedAIComponent?: {
    index: number,
    isNew: boolean,
    itemType: NovoAIItemType,
  }

  // For AI-Generated content
  generatedAIContent?: {
    discussionPrompts: AIDiscussionPrompts;
  }

  // For Discussion Translations (comments and replies)
  discussionTranslation: {
    comments: Record<number, DiscussionTranslation>;
    replies: Record<number, DiscussionTranslation>;
  }

  // Athena - Mentorship Programs
  connections: {
    createConnectionModal?: {
      showModal?: boolean;
      type?: CreateConnectionType;
      enrollmentId?: number;
    }
  }

  // Athena mentoring
  mentoringProgram: {
    autoMatching: {
      status: null | AutoMatchingStatus,
    },
    settings: {
      saveStatus: SaveStatus | null,
      profile: {
        isLoading: boolean;
      }
      matchingRules: {
        resetList: boolean;
        totalCount: number;
      }
    }
    programCounts: {
      isLoading: boolean;
      result: null | ProgramCounts,
    },
    participantCounts: {
      isLoading: boolean;
      result: null | ParticipantCounts,
    },
    participantView: {
      refresh: boolean;
    }
  },
  supervisorDashboard: {
    pendingSubmissions: VideoPracticeSubmissionsNormalized,
    isLoadingPendingSubmissions: boolean,
    isLoadingScenariosPendingReview: boolean,
    totalCount: number,
    courses: { [id: string]: BasicMentee }
    isLoadingSupervisorCourses: boolean,
    supervisorCoursesTotalCount: number,
  },
}

export interface RootState {
  models: ModelsState;
  app: AppState;
}
