import React from 'react';
import { css } from '@emotion/react';
import { Controller } from 'react-hook-form';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { mergeRefs } from 'shared/react-utils';
import NvPopover from 'shared/components/nv-popover';
import { danger, gray2 } from 'styles/global_defaults/colors';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import { almostBlack, darkGray, dividerMedium } from 'athena/styles/colors';
import { openSans, textMediumFontSize, textMediumLineHeight } from 'styles/global_defaults/fonts';
import {
  doubleSpacing,
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';

export enum AthenaTextInputType {
  TEXT = 'text',
  SEARCH = 'search',
}

type BaseAthenaTextInputProps = Omit<React.ComponentProps<'input'>, 'className'> & {
  icon?: string;
  error?: string;
  className?: string;
  showLabel?: boolean;
  inputClassName?: string;
  iconPosition?: 'left' | 'right';
};

const BaseAthenaTextInput = React.forwardRef<HTMLInputElement, BaseAthenaTextInputProps>((props, ref) => {
  const {
    icon,
    error,
    onBlur,
    onFocus,
    required,
    onChange,
    className,
    placeholder,
    inputClassName,
    showLabel = true,
    value: propsValue,
    iconPosition = 'left',
    type = AthenaTextInputType.TEXT,
    ...restProps
  } = props;

  const [value, setValue] = React.useState(propsValue);
  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  const styles = css`
    .input-label {
      left: 0;
      font-weight: 600;
      color: ${darkGray};
      top: -${standardSpacing}px;
    }

    .athena-input-container {
      .icon-container {
        height: 100%;
        ${iconPosition}: 0;
        width: ${doubleSpacing}px;
      }

      .athena-input {
        width: 100%;
        padding: ${halfSpacing}px;
        height: ${doubleSpacing}px;
        border-radius: ${quarterSpacing}px;
        color:  ${value ? almostBlack : gray2};
        border: 1px solid ${error ? danger : dividerMedium};

        // Defining font styles explicitely and not using "text-medium" class in
        // input because of a specificity issue.
        font-family: ${openSans};
        font-size: ${textMediumFontSize}px!important;
        line-height: ${textMediumLineHeight}px!important;

        ${required && css`
          padding-right: ${largeSpacing}px;
        `};

        ${icon && css`
          padding-${iconPosition}: ${doubleSpacing}px;
        `};
      }

      .required-indicator {
        top: 0;
        right: 0;
        height: 100%;
        width: ${largeSpacing}px;
      }

      .clear-button {
        right: ${quarterSpacing}px;
        top: ${threeQuartersSpacing}px;
        border: none;
        background: none;
      }
    }
  `;

  const handleChange = (e) => {
    setValue(e?.target?.value ?? '');

    onChange?.(e);
  };

  const handleFocus = (e) => {
    setIsFocused(true);

    onFocus?.(e);
  };

  const handleBlur = (e) => {
    setIsFocused(false);

    onBlur?.(e);
  };

  return (
    <div css={styles} className={className}>
      {showLabel && (
        <div className='input-label text-small mb-1'>
          {placeholder}
        </div>
      )}
      <div className='athena-input-container position-relative'>
        {!!icon && (
          <div className='position-absolute d-flex align-items-center justify-content-center icon-container'>
            <NvIcon icon={icon} size='small' />
          </div>
        )}
        <NvPopover
          className='nv-text-input'
          show={!!error && isFocused}
          // NOTE: Using existing translation to not create a new one.
          content={<ValidationErrorMessage text={error} title={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.PHRASE_VALIDATION.ERROR()} />}
        >
          <input
            ref={ref}
            type='text'
            value={value}
            onBlur={handleBlur}
            required={required}
            onFocus={handleFocus}
            onChange={handleChange}
            placeholder={placeholder}
            className={`athena-input ${inputClassName ?? ''}`}
            {...restProps}
          />
        </NvPopover>
        {required && (
          <div className='required-indicator position-absolute d-flex align-items-center justify-content-center text-medium gray-3'>
            *
          </div>
        )}
        {type === AthenaTextInputType.SEARCH && value && (
          <button
            className='clear-button position-absolute d-flex align-items-center justify-content-center'
            onClick={handleChange}
            type='button'
          >
            <NvIcon icon='close' size='xss-smallest' color={gray2} />
          </button>
        )}
      </div>
    </div>
  );
});

type Props = BaseAthenaTextInputProps & {
  withForm?: boolean;
};

const AthenaTextInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    name,
    value,
    error,
    onBlur,
    onChange,
    withForm,
    type = AthenaTextInputType.TEXT,
    ...restProps
  } = props;

  return withForm ? (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        const handleBlur = (e) => {
          onBlur?.(e);
          field.onBlur();
        };

        const handleChange = (e) => {
          onChange?.(e);
          field.onChange(e);
        };

        return (
          <BaseAthenaTextInput
            name={field.name}
            onBlur={handleBlur}
            onChange={handleChange}
            value={value ?? field.value}
            error={error ?? fieldState.error?.message}
            {...restProps}
            ref={mergeRefs(ref, field.ref)}
            type={type}
          />
        );
      }}
    />
  ) : (
    <BaseAthenaTextInput
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      {...restProps}
      ref={ref}
      type={type}
    />
  );
});

export default AthenaTextInput;
