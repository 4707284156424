import { css } from '@emotion/react';
import {
  doubleSpacing,
  quarterSpacing,
} from 'styles/global_defaults/scaffolding';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import NvTable, { Column } from 'shared/components/nv-table';
import {
  MentorshipProgramConnection,
  MentorshipProgramConnectionMember,
} from 'redux/schemas/models/mentorship-program-connections';
import AthenaTextInput, { AthenaTextInputType } from 'athena/components/text-input';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import { useAppDispatch } from 'redux/store';
import {
  deleteMentorshipProgramConnection,
  getMentorshipProgramConnections,
} from 'redux/actions/mentorship-program-connections';
import t from 'react-translate';
import { lightFontWeight } from 'styles/global_defaults/fonts';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import { wrapThunkAction } from 'redux/utils';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { getMentoringProgramConnectionsList } from 'redux/selectors/mentorship-program-connections';
import { debounce } from 'lodash';
import { darkGray, alto } from 'athena/styles/colors';
import { hexToRgbaString } from 'styles/global_defaults/colors';
import { ReactComponent as EmptyConnections } from 'styles/icons/empty-connections.svg';
import { config } from '@config/pendo.config.json';
import { useSelector } from 'react-redux';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import ConfirmationModal, {
  ModalContentProps,
} from '../modals/confirmation-modal';
import { ColumnID } from './connection-row/types';
import ConnectionRow from './connection-row';
import MentoringProgramContext, {
  MentoringProgramHomeUIContext,
} from '../context';
import LoadingRow from './loading-row';
import { PAGE_SIZE, PageSizeParams } from '../constants';
import NvNoResults from 'shared/components/nv-no-results-panel';

const styles = (scrollEnabled: boolean) => css`
  .search-input {
    flex: 1;
    height: ${doubleSpacing + quarterSpacing}px;
    .search-input-element {
      font-weight: ${lightFontWeight};
    }
  }
  .table-body {
    overflow-y: ${scrollEnabled ? 'auto' : 'hidden'};

    .empty-ui {
      height: 250px;
      color: ${darkGray};
      background-color: ${hexToRgbaString(alto, 0.2)};
    }

    .empty-title {
      width: 220px;
    }
  }
`;

const ROW_HEIGHT = 70;
const CONNECTION_TYPE_WIDTH = 44;
const OPTIONS_WIDTH = 56;

const fallbackData = [];

type ConnectionsParams = PageSizeParams & {
  textSearch: string;
};

type ConnectionsProps = {
  scrollEnabled: boolean;
  onScroll: React.ComponentProps<'div'>['onScroll'];
};

const Connections = ({ scrollEnabled, onScroll }: ConnectionsProps) => {
  const dispatch = useAppDispatch();
  const scrollRef = useRef<HTMLDivElement>();
  // Detect when there is less than 3 rows
  const reachedEnd = useInfiniteScroll(scrollRef.current, ROW_HEIGHT * 3);
  const {
    countState,
    mentoringProgram: { id: mentorshipProgramId },
  } = useContext(MentoringProgramContext);
  const mentoringProgramAliases = useSelector((state) => getMentoringProgramAliases(state, mentorshipProgramId));
  const { withOverflowFlexGrowClass } = useContext(
    MentoringProgramHomeUIContext,
  );
  const [textSearch, setTextSearch] = useState<string>(null);

  const bodyProps = {
    onScroll,
    ref: scrollRef,
    className: `table-body ${withOverflowFlexGrowClass}`,
  };
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(
    false,
  );
  const [modalContentProps, setModalContentProps] = useState<ModalContentProps>(
    null,
  );

  const connectionsTableColumns: Column<
  MentorshipProgramConnection
  >[] = useMemo(
    () => [
      {
        id: ColumnID.TITLE,
        enableSorting: true,
        accessorFn: (row) => row,
        header: t.MENTORING_PROGRAMS.CONNECTIONS_TABLE.COLUMNS.TITLE(mentoringProgramAliases),
      },
      {
        id: ColumnID.CONNECTION_TYPE,
        gridTemplateColumn: `${CONNECTION_TYPE_WIDTH}px`,
      },
      {
        id: ColumnID.OPTIONS,
        gridTemplateColumn: `${OPTIONS_WIDTH}px`,
      },
    ],
    [],
  );

  const [sorting, setSorting] = React.useState([{
    id: ColumnID.TITLE,
    desc: true,
  }]);

  const params = useMemo(
    () => {
      const sortingObject: any = {};

      const currentSorting = sorting[0];

      if (currentSorting) {
        const { id, desc } = currentSorting;

        sortingObject.sortBy = id;
        sortingObject.sortDesc = desc;
      }

      return {
        pageSize: PAGE_SIZE,
        textSearch: textSearch?.trim() || undefined,
        ...sortingObject,
      };
    },
    [textSearch, sorting],
  );

  const { reset, result, loadMore, isLoading } = usePaginatedApi<
  MentorshipProgramConnection,
  ConnectionsParams
  >(
    p => dispatch(
      getMentorshipProgramConnections({
        mentorshipProgramId,
        ...p,
      }),
    ).then(action => action.payload),
    params,
    getMentoringProgramConnectionsList,
  );

  const [connectionsTableData, setConnectionsTableData] = useState(result);

  useEffect(() => {
    setConnectionsTableData(result);
  }, [result]);

  const debounceSetTextSearch = useCallback(
    debounce(setTextSearch, 500),
    [],
  );

  useEffect(() => {
    if (reachedEnd) {
      loadMore();
    }
  }, [loadMore, reachedEnd]);

  const onUnpairConnection = mentorshipProgramConnectionId => wrapThunkAction(
    dispatch(
      deleteMentorshipProgramConnection({
        mentorshipProgramId,
        mentorshipProgramConnectionId,
      }),
    ),
  )
    .then(() => {
      dispatch(
        addAlertMessage({
          header: t.FORM.SUCCESS(),
          type: AlertMessageType.SUCCESS,
          message: t.MENTORING_PROGRAMS.CONNECTIONS_TABLE.ROWS.MENU.UNPAIR_CONNECTION.CONFIRMATION_MODAL.SUCCESS_MESSAGE(),
        }),
      );
      reset();
      countState.refresh();
    })
    .catch(() => dispatch(
      addAlertMessage({
        header: t.FORM.ERROR(),
        type: AlertMessageType.ERROR,
      }),
    ))
    .finally(() => setShowConfirmationModal(false));

  const getEnrollmentsByRole = (
    members: MentorshipProgramConnectionMember[],
    role: MentoringProgramRole,
  ) => members
    .filter(item => item.mentorshipProgramEnrollment.role.name === role)
    .map(item => item.mentorshipProgramEnrollment);

  const handleUnpairConnection = (connection: MentorshipProgramConnection) => {
    const {
      id: mentorshipProgramConnectionId,
      mentorshipProgramConnectionMembers,
    } = connection;

    const [mentor] = getEnrollmentsByRole(
      mentorshipProgramConnectionMembers,
      MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR,
    );
    const [mentee] = getEnrollmentsByRole(
      mentorshipProgramConnectionMembers,
      MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE,
    );
    const mentorFullName = mentor.user.fullName;
    const menteeFullName = mentee.user.fullName;

    setModalContentProps({
      content: t.MENTORING_PROGRAMS.CONNECTIONS_TABLE.ROWS.MENU.UNPAIR_CONNECTION.CONFIRMATION_MODAL.CONTENT(
        menteeFullName,
        mentorFullName,
      ),
      confirmButtonType: 'danger',
      confirmButtonLabel: t.MENTORING_PROGRAMS.CONNECTIONS_TABLE.ROWS.MENU.UNPAIR_CONNECTION.CONFIRMATION_MODAL.CONFIRM_BUTTON_LABEL(),
      onConfirm: () => onUnpairConnection(mentorshipProgramConnectionId),
      dataQa: config.pendo.athena.mentorshipProgram.connections.connectionDeleted,
    });
    setShowConfirmationModal(true);
  };

  return (
    <>
      <div
        css={styles(scrollEnabled)}
        className={`d-flex flex-column ${withOverflowFlexGrowClass}`}
      >
        <div className='d-flex mb-4'>
          <AthenaTextInput
            icon='search'
            type={AthenaTextInputType.SEARCH}
            value={textSearch}
            showLabel={false}
            className='search-input mr-3'
            inputClassName='search-input-element'
            onChange={({ target: { value } }) => debounceSetTextSearch(value)}
            placeholder={t.MENTORING_PROGRAMS.CONNECTIONS.SEARCH_PLACEHOLDER()}
          />
        </div>
        <NvTable<MentorshipProgramConnection>
          data={connectionsTableData ?? fallbackData}
          onSortingChange={setSorting}
          RowComponent={ConnectionRow}
          className={`d-flex flex-column ${withOverflowFlexGrowClass}`}
          bodyProps={bodyProps}
          columns={connectionsTableColumns}
          meta={{
            handleUnpairConnection,
          }}
          state={{
            sorting,
          }}
          renderAfterRows={(afterRowsProps) => {
            if (result != null && !result.length) {
              return (
                <NvNoResults
                  hideClearSearch={false}
                  action={() => setTextSearch('')}
                  clearText={t.SEARCH.CLEAR()}
                  noResultsText={t.SEARCH.NO_RESULTS_FOUND()}
                />
              );
            }

            return isLoading && <LoadingRow {...afterRowsProps} />;
          }}
        />
      </div>
      <ConfirmationModal
        showModal={showConfirmationModal}
        {...modalContentProps}
        onClose={() => setShowConfirmationModal(false)}
      />
    </>
  );
};

export default Connections;
