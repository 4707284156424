import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ParticipantCounts } from 'redux/schemas/app/mentoring-program-participants';
import {
  MentoringProgramRole,
  MentoringProgramEnrollment,
  MentoringProgramEnrollmentResult,
} from 'redux/schemas/models/mentoring-program-enrollments';

type GetMentoringProgramEnrollmentsParams = {
  page: number;
  pageSize: number;
  programId: number;
  textSearch?: string;
  courseRoleName?: MentoringProgramRole;
  sortBy?: 'name' | 'status' | 'connections' | 'assigned';
  sortDesc?: boolean;
  updateCounts?: boolean;
};

const sortByToParamPrefix = {
  name: 'full_name_',
  status: 'mentorship_status_',
  connections: 'connection_count_',
  assigned: 'assigned_connection_',
};

export const getMentoringProgramEnrollment = createAsyncThunk<
MentoringProgramEnrollment,
{ programId: number; enrollmentId: number }
>('GET_MENTORING_PROGRAM_ENROLLMENT', async ({ programId, enrollmentId }) => {
  const response = await axios.get(
    `mentorship_programs/${programId}/mentorship_program_enrollment_management/${enrollmentId}`,
  );
  return response.data.result;
});

type GetMentoringProgramEnrollmentsResponse = {
  mentorshipProgramEnrollments: MentoringProgramEnrollment[];
  countByRole: ParticipantCounts;
};

// eslint-disable-next-line import/prefer-default-export
export const getMentoringProgramEnrollments = createAsyncThunk<
MentoringProgramEnrollment[],
GetMentoringProgramEnrollmentsParams
>(
  'GET_MENTORING_PROGRAM_ENROLLMENTS',
  async (
    { programId, page, pageSize, textSearch, courseRoleName, sortBy, sortDesc, updateCounts },
    thunkAPI,
  ) => {
    let sortingParam;

    if (sortBy) {
      const suffix = sortDesc ? 'desc' : 'asc';

      sortingParam = `${sortByToParamPrefix[sortBy]}${suffix}`;
    }

    const params = {
      page,
      page_size: pageSize,
      text_search: textSearch || undefined,
      course_role_name: courseRoleName || undefined,
    };

    if (sortingParam) {
      params[sortingParam] = 1;
    }

    const response = await axios.get(
      `mentorship_programs/${programId}/mentorship_program_enrollment_management`,
      {
        params,
      },
    );
    const { mentorshipProgramEnrollments, countByRole } = response.data
      .result as GetMentoringProgramEnrollmentsResponse;
    if (updateCounts) thunkAPI.dispatch(updateProgramParticipantCounts({ counts: countByRole }));
    return mentorshipProgramEnrollments;
  },
);

type EnrollUserToProgramParams = {
  programId: number;
  role: MentoringProgramRole;
  firstName?: string;
  lastName?: string;
  email: string;
  userId?: number;
};

export const enrollUserToProgram = createAsyncThunk<
MentoringProgramEnrollmentResult,
EnrollUserToProgramParams
>(
  'ENROLL_USER_TO_PROGRAM',
  async ({ role, email, firstName, lastName, programId, userId }) => {
    const response = await axios.post(
      `/mentorship_programs/${programId}/mentorship_program_enrollment_management/create.json`,
      {
        courseRoleName: role,
        users: [
          {
            email,
            lastName,
            firstName,
            userId: userId || undefined,
          },
        ],
      },
    );

    return response.data.result;
  },
);

type UpdateMentoringProgramEnrollment = {
  programId: number;
  enrollmentId: number;
  role: MentoringProgramRole;
  firstName: string;
  lastName: string;
  email: string;
};

export const updateMentoringProgramEnrollment = createAsyncThunk<
boolean,
UpdateMentoringProgramEnrollment
>(
  'UPDATE_MENTORING_PROGRAM_ENROLLMENT',
  async ({ role, email, firstName, lastName, programId, enrollmentId }) => {
    const response = await axios.put(
      `/mentorship_programs/${programId}/mentorship_program_enrollment_management/${enrollmentId}.json`,
      {
        email,
        lastName,
        firstName,
        courseRoleName: role,
      },
    );

    return response.data.result;
  },
);

type FetchProgramParticipantCounts = {
  setLoading: boolean;
  programId: number;
};

export const fetchProgramParticipantCounts = createAsyncThunk<
ParticipantCounts,
FetchProgramParticipantCounts
>('FETCH_PROGRAM_PARTICIPANT_COUNTS', async ({ programId }) => {
  const response = await axios.get(
    `/mentorship_programs/${programId}/mentorship_program_enrollment_management/enrollment_count.json`,
  );

  return response.data.result;
});

type DeleteMentoringProgramEnrollmentParams = {
  programId: number;
  enrollmentId: number;
};

export const deleteMentoringProgramEnrollment = createAsyncThunk<
boolean,
DeleteMentoringProgramEnrollmentParams
>('DELETE_MENTORING_PROGRAM_ENROLLMENT', async params => {
  const response = await axios.delete(
    `/mentorship_programs/${params.programId}/mentorship_program_enrollment_management/${params.enrollmentId}.json`,
  );

  return response.data.result;
});

type BulkUpdateParams = {
  programId: number;
  fileName: string;
  courseRoleName?: string;
};

export const validateMentoringProgramEnrollmentsCSV = createAsyncThunk(
  'VALIDATE_MENTORING_PROGRAM_ENROLLMENTS_CSV',
  async ({ programId, fileName }: BulkUpdateParams) => axios
    .post(
      `/mentorship_programs/${programId}/mentorship_program_enrollment_management/validate_csv.json`,
      { file_name: fileName },
    )
    .then(response => response.data.result),
);

export const createMentoringProgramEnrollmentsFromCSV = createAsyncThunk(
  'CREATE_MENTORING_PROGRAM_ENROLLMENTS_FROM_CSV',
  async ({ programId, fileName, courseRoleName }: BulkUpdateParams) => axios
    .post(
      `/mentorship_programs/${programId}/mentorship_program_enrollment_management/create_from_csv.json`,
      { file_name: fileName, course_role_name: courseRoleName },
    )
    .then(response => response.data.result),
);

type UnEnrollParticipantFromProgramParams = {
  programId: number;
  email: string;
};

export const removeMentoringProgramEnrollment = createAsyncThunk<
boolean,
UnEnrollParticipantFromProgramParams
>('UNENROLL_MENTORING_PROGRAM_ENROLLMENT', async ({ programId, email }) => {
  const response = await axios.post(
    `/mentorship_programs/${programId}/mentorship_program_enrollment_management/unenroll`,
    {
      users: [
        {
          email,
        },
      ],
    },
  );

  return response.data.result;
});

export const updateProgramParticipantCounts = createAction<{
  counts: ParticipantCounts;
}>('UPDATE_MENTORING_PROGRAM_PARTICIPANT_COUNTS');

export const refreshProgramParticipantsView = createAction<{
  refreshView: boolean;
}>('REFRESH_PROGRAM_PARTICIPANTS_VIEW');
